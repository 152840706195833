.commentSection {
font-size:14px;
line-height:1.358;
word-break:break-word;
word-wrap:break-word;
}
._2pi8 {
padding-left:8px;
padding-right:8px;
}

.clearFix {
zoom:1;
}

._491z {
border-bottom:1px solid #e9ebee;
line-height:25px;
margin-bottom:24px;
padding:8px 0;
}

.clearFix:after {
clear:both;
content:".";
display:block;
font-size:0;
height:0;
line-height:0;
visibility:hidden;
}

._4qba {
font-style:normal;
}

._2ph_ {
    padding: 12px;
}

._2392 {
margin-bottom:24px;
}

._8s {
margin-right:8px;
}

._8o,._8o .img {
display:block;
border-radius: 100% 100% 100% 100%;
}

a {
color:#365899;
cursor:pointer;
text-decoration:none;
}

._1ci {
height:48px !important;
width:48px !important;
object-fit: cover;
}

img {
border:0;
}

._42ef {
margin-bottom:4px;
margin-top:4px;
overflow:hidden;
}

._4uyl ._1cb {
border:1px solid #d3d6db;
word-wrap:break-word;
}

._5yk1 {
background:#fff;
border:1px solid #bdc7d8;
box-sizing:border-box;
cursor:text;
font-size:11px;
padding:3px;
}

._4uyl ._5yk2,._4uyl ._1u9t {
font-size:14px;
}

._5rp7,._5rpb,._5rpu {
height:inherit;
text-align:initial;
}

._4uyl ._1p1t {
color:#a9a9a9;
}

._1p1t {
color:#90949c;
position:absolute;
z-index:0;
}

._5rpb {
background-color:rgba(255,255,255,0);
border-left:.1px solid transparent;
position:relative;
z-index:1;
}

._5rpu[contenteditable=true] {
-webkit-user-modify:read-write-plaintext-only;
}

._1mj {
direction:ltr;
text-align:left;
}

._1mf {
position:relative;
white-space:pre-wrap;
}

.UFICommentAttachmentButtons {
bottom:0;
height:24px;
position:absolute;
right:0;
}

._4uym {
border:1px solid #d3d6db;
border-top:0;
}

._2ph- {
padding:8px;
}

._5tr6 {
background:#f6f7f9;
text-align:right;
}








td,td.label {
text-align:left;
}

form.async_saving ._4jy1,a.async_saving._4jy1,._4jy1._42fr {
font-weight: normal !important;
background-color: #cacaca;
border-color: #cacaca;
}

._5tr6 button,._5tr6 ._42fr:active,._5tr6 ._42fs {
font-size:14px;
white-space:normal;
}

._3-99 {
margin-left:4px;
}

._4jy3,._4jy3._42fr:active,._4jy3._42fr._42fs {
line-height:22px;
}

._4jy1 {
background-color:#28B1E9;
border-color:#28B1E9;
}

._4jy1,._4jy2 {
color:#fff !important;
}

._4jy0 {
border:1px solid;
border-radius:2px;
box-sizing:content-box;
font-size:12px !important;
-webkit-font-smoothing:antialiased;
font-weight:700 !important;
position:relative;
text-align:center;
text-shadow:none;
vertical-align:middle;
padding:0 8px;
}

.rfloat {
float:right;
}

._42fr,._42fs {
cursor:default;
}

._42ft {
cursor:pointer;
display:inline-block;
text-decoration:none;
white-space:nowrap;
}

button {
margin:0;
}

._3-8y {
margin-top:12px;
}

._5mdd {
word-wrap:break-word;
}

.fwn {
font-weight:400;
}

.fsm {
font-size:12px;
}

._2vq9 {
font-size:12px;
line-height:1.358;
padding-top:2px;
}

._2vq9 a {
color:#4267b2;
}

._2vq9 .livetimestamp,._2vq9 .uiLinkSubtle {
color:#90949c;
}

abbr {
border-bottom:none;
text-decoration:none;
}

._42ft:hover {
text-decoration:none;
}

._5o4h ._1gl3 {
background:#28B1E9;
border:none;
box-sizing:border-box;
color:#fff;
font-size:14px;
margin-top:24px;
text-shadow:none;
width:100%;
padding:12px;
}

._517h,._59pe:focus,._59pe:hover {
background-color:#f6f7f9;
color:#4b4f56;
border-color:#ced0d4;
}

._2pi3 {
padding-bottom:8px;
padding-top:8px;
}

._5lm5 {
border-top:1px solid #e9ebee;
font-size:11px;
}

.sp_Zf93eLkohoS.sx_97c3ab {
width:14px;
height:14px;
background-position:-19px -182px;
}

i.img {
-ms-high-contrast-adjust:none;
_overflow:hidden;
}

._8r {
margin-right:5px;
}

._1cj {
height:36px;
width:36px;
}

._ohe,.lfloat {
float:left;
}

._50f7,.UFICommentActorName {
font-weight:700;
}

.UFIMentionsInputWrap,.UFICommentContainer,.UFIInputContainer,._5rp7 {
position:relative;
}

body.plugin {
background:transparent;
line-height:1.28;
overflow:hidden;
-webkit-text-size-adjust:none;
}

._4mr9 {
-webkit-touch-callout:none;
-webkit-user-select:none;
}

.plugin,.plugin button,.plugin input,.plugin label,.plugin select,.plugin td,.plugin textarea {
font-size:11px;
}

._51mz {
margin-bottom: 10px;
border:0;
border-collapse:collapse;
border-spacing:0;
}





td,td.label {
text-align:left;
}



.uiGrid .hCent {
text-align:center;
}

form {
margin:0;
padding:0;
}

._2tga._49ve {
    border-radius: 3px;
    font-size: 16px;
    height: 35px;
    padding: 0 10px 0 10px;
}

._2tga.active {
background:#4080ff;
border:1px solid #4080ff;
}

._49ve {
background:#28B1E9;
border:1px solid #28B1E9;
color:#fff;
cursor:pointer;
-webkit-font-smoothing:antialiased;
-webkit-user-select:none;
white-space:nowrap;
margin:0;
}

button {
margin:0;
}

a {
color:#365899;
cursor:pointer;
text-decoration:none;
}

#facebook ._2tga span._49vh,#facebook ._2tga span._5n6h,._49vh,._5n6h {
vertical-align:middle;
}

._2n-v ._49vh {
padding-left:2px;
}

._49vh {
font-weight:700;
}

._2pi7 {
padding-left:4px;
padding-right:4px;
}

._2pic {
padding-top:4px;
}

._43qc ._4cg3 {
margin-top:-1px;
min-height: 33px;
}

ul {
list-style-type:none;
margin:0;
padding:0;
}

.uiList>li:first-child {
border-width:0;
}

._43qc ._43q7 {
margin:1px 1px 0 0;
}

.pluginFacepileConnectLast,.pluginFacepileConnectConnected .pluginFacepileConnectFirst {
animation:show .4s;
display:inline-block!important;
}

._4ki>li {
display:inline-block;
border-width:0 0 0 1px;
}

._43q7 a {
display:block;
position:relative;
}

._s0:only-child {
display:block;
}

._43q7 .img {
vertical-align:bottom;
border-radius: 100% 100% 100% 100%;
}

._54ru {
height:32px !important;
width:32px !important;
object-fit: cover;
}

img {
border:0;
}

.uiGrid .vTop,._509->li {
vertical-align:top;
}

.pluginButtonSpacer,._2pir {
padding-left:4px;
}

#facebook .hidden_elem,.pluginFacepileConnectFirst,.pluginFacepileConnectConnected .pluginFacepileConnectLast {
display:none!important;
}

._3jn- {
height:16px;
vertical-align:middle;
width:16px;
}

._3jn_ {
background:none;
display:none;
height:28px;
left:-6px;
position:absolute;
top:-6px;
width:28px;
}

._49vg,._5n2y {
vertical-align:middle;
}

._2tga ._1pbq {
height:16px;
width:16px;
}


._2tga ._4kaf,._2tga.deactive ._5n2y,._2tga.active ._49vg,._2tga.active.is_animating ._5n2y,._2tga.active.is_animating.stop_hoverx ._4kaf,._2tga.active.stop_hoverx:hover ._4kag,._2tga.active:hover ._5n2y._4kaf,.no_svg ._2tga ._1pbq,.svg ._2tga ._1pbs {
    display:none;
}



.DIV_1 {
    /* border-top: 1px solid rgb(239, 239, 239); */
    border-bottom: 1px solid rgb(239, 239, 239);
    padding: 5px 0px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 14px;
}/*#DIV_1*/

.H4_3 {
    float: left;
    height: 21.6px;
    text-size-adjust: 100%;
    perspective-origin: 27px 10.8px;
    transform-origin: 27px 10.8px;
    margin: 0px;
    font-weight: 700;
    line-height: 25px;
    font-size: 14px;
}/*#H4_3*/




.SPAN_5, .SPAN_6 {
    color: rgb(128, 128, 128);
    display: inline-block;
    height: 0px;
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    width: 0px;
    column-rule-color: rgb(128, 128, 128);
    perspective-origin: 0px 0px;
    transform-origin: 0px 0px;
    caret-color: rgb(128, 128, 128);
    border: 0px none rgb(128, 128, 128);
    outline: rgb(128, 128, 128) none 0px;
}/*#SPAN_5, #SPAN_6*/


.DIV_7 {
    bottom: 0px;
    color: rgb(128, 128, 128);
    left: 0px;
    position: relative;
    right: 0px;
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    top: 0px;
    /*width: 100%;*/
    column-rule-color: rgb(128, 128, 128);
    perspective-origin: 275px 46.5px;
    transform-origin: 275px 46.5px;
    caret-color: rgb(128, 128, 128);
    border: 0px none rgb(128, 128, 128);
    outline: rgb(128, 128, 128) none 0px;
    overflow: hidden;
}/*#DIV_7*/



.UL_8 {
    color: rgb(128, 128, 128);
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    width: 100%;
    column-rule-color: rgb(128, 128, 128);
    perspective-origin: 275px 92.6px;
    transform-origin: 275px 92.6px;
    caret-color: rgb(128, 128, 128);
    border: 0px none rgb(128, 128, 128);
    margin: 0px;
    outline: rgb(128, 128, 128) none 0px;
    padding: 0px;
}/*#UL_8*/



.LI_9, .LI_14, .LI_20, .LI_26, .LI_32, .LI_37, .LI_43 {
    color: rgb(128, 128, 128);
    float: left;
    text-align: center;
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    width: 70px;
    column-rule-color: rgb(128, 128, 128);
    perspective-origin: 45.375px 46.3px;
    transform-origin: 45.375px 46.3px;
    caret-color: rgb(128, 128, 128);
    border: 0px none rgb(128, 128, 128);
    list-style: none outside none;
    outline: rgb(128, 128, 128) none 0px;
    padding: 10px 0px;
}/*.LI_9, .LI_14, .LI_20, .LI_26, .LI_32, .LI_37, .LI_43*/


.A_10, .A_15, .A_21, .A_27, .A_33, .A_38, .A_44 {
    bottom: 0px;
    display: block;
    height: 50px;
    left: 0px;
    position: relative;
    right: 0px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    top: 0px;
    width: 50px;
    perspective-origin: 25px 25px;
    transform-origin: 25px 25px;
    list-style: none outside none;
    margin: 0px 0px;
}/*.A_10, .A_15, .A_21, .A_27, .A_33, .A_38, .A_44*/


.IMG_11, .IMG_16, .IMG_22, .IMG_28, .IMG_34, .IMG_39, .IMG_45 {
    color: rgb(0, 0, 238);
    cursor: pointer;
    display: block;
    height: 50px !important;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    width: 50px !important;
    column-rule-color: rgb(0, 0, 238);
    perspective-origin: 25px 25px;
    transform-origin: 25px 25px;
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    border-radius: 100% 100% 100% 100%;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
    object-fit: cover;
}/*.IMG_11, .IMG_16, .IMG_22, .IMG_28, .IMG_34, .IMG_39, .IMG_45*/


.P_12, .P_18, .P_24, .P_30, .P_35, .P_41, .P_47 {
    color: rgb(128, 128, 128);
    height: 17.6px;
    text-align: center;
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    width: 50px;
    column-rule-color: rgb(128, 128, 128);
    perspective-origin: 45.375px 11.3px;
    transform-origin: 45.375px 11.3px;
    caret-color: rgb(128, 128, 128);
    border: 0px none rgb(128, 128, 128);
    list-style: none outside none;
    margin: 0px;
    outline: rgb(128, 128, 128) none 0px;
    padding: 5px 0px 0px;
}/*.P_12, .P_18, .P_24, .P_30, .P_35, .P_41, .P_47*/


.SPAN_13, .SPAN_19, .SPAN_25, .SPAN_31, .SPAN_42, .SPAN_48 {
    color: rgb(128, 128, 128);
    display: inline-block;
    height: 0px;
    text-align: center;
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    width: 0px;
    column-rule-color: rgb(128, 128, 128);
    perspective-origin: 0px 0px;
    transform-origin: 0px 0px;
    caret-color: rgb(128, 128, 128);
    border: 0px none rgb(128, 128, 128);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(128, 128, 128) none 0px;
}/*.SPAN_13, .SPAN_19, .SPAN_25, .SPAN_31, .SPAN_42, .SPAN_48*/

.SPAN_13:after, .SPAN_19:after, .SPAN_25:after, .SPAN_31:after, .SPAN_42:after, .SPAN_48:after {
    color: rgb(128, 128, 128);
    text-align: center;
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    column-rule-color: rgb(128, 128, 128);
    caret-color: rgb(128, 128, 128);
    border: 0px none rgb(128, 128, 128);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(128, 128, 128) none 0px;
}/*.SPAN_13:after, .SPAN_19:after, .SPAN_25:after, .SPAN_31:after, .SPAN_42:after, .SPAN_48:after*/

.SPAN_13:before, .SPAN_19:before, .SPAN_25:before, .SPAN_31:before, .SPAN_42:before, .SPAN_48:before {
    color: rgb(128, 128, 128);
    text-align: center;
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    column-rule-color: rgb(128, 128, 128);
    caret-color: rgb(128, 128, 128);
    border: 0px none rgb(128, 128, 128);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(128, 128, 128) none 0px;
}/*.SPAN_13:before, .SPAN_19:before, .SPAN_25:before, .SPAN_31:before, .SPAN_42:before, .SPAN_48:before*/

.SPAN_17 {
    background-position: 0px -17px;
    bottom: 47px;
    color: rgb(0, 0, 238);
    cursor: pointer;
    display: block;
    height: 15px;
    left: 15px;
    position: absolute;
    right: 15px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    top: -12px;
    width: 20px;
    column-rule-color: rgb(0, 0, 238);
    perspective-origin: 10px 7.5px;
    transform-origin: 10px 7.5px;
    caret-color: rgb(0, 0, 238);
    background: rgba(0, 0, 0, 0) -webkit-image-set(url("https://www.ikara.co/img/sprite_crown.png") 2x) repeat scroll 0px -17px / auto padding-box border-box;
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_17*/

.SPAN_17:after {
    color: rgb(0, 0, 238);
    cursor: pointer;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_17:after*/

.SPAN_17:before {
    color: rgb(0, 0, 238);
    cursor: pointer;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_17:before*/

.SPAN_23 {
    background-position: 0px -33px;
    bottom: 47px;
    color: rgb(0, 0, 238);
    cursor: pointer;
    display: block;
    height: 15px;
    left: 15px;
    position: absolute;
    right: 15px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    top: -12px;
    width: 20px;
    column-rule-color: rgb(0, 0, 238);
    perspective-origin: 10px 7.5px;
    transform-origin: 10px 7.5px;
    caret-color: rgb(0, 0, 238);
    background: rgba(0, 0, 0, 0) -webkit-image-set(url("https://www.ikara.co/img/sprite_crown.png") 2x) repeat scroll 0px -33px / auto padding-box border-box;
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_23*/

.SPAN_23:after {
    color: rgb(0, 0, 238);
    cursor: pointer;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_23:after*/

.SPAN_23:before {
    color: rgb(0, 0, 238);
    cursor: pointer;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_23:before*/

.SPAN_29 {
    background-position: 0px -49px;
    bottom: 47px;
    color: rgb(0, 0, 238);
    cursor: pointer;
    display: block;
    height: 15px;
    left: 15px;
    position: absolute;
    right: 15px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    top: -12px;
    width: 20px;
    column-rule-color: rgb(0, 0, 238);
    perspective-origin: 10px 7.5px;
    transform-origin: 10px 7.5px;
    caret-color: rgb(0, 0, 238);
    background: rgba(0, 0, 0, 0) -webkit-image-set(url("https://www.ikara.co/img/sprite_crown.png") 2x) repeat scroll 0px -49px / auto padding-box border-box;
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_29*/

.SPAN_29:after {
    color: rgb(0, 0, 238);
    cursor: pointer;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_29:after*/

.SPAN_29:before {
    color: rgb(0, 0, 238);
    cursor: pointer;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_29:before*/

.SPAN_36 {
    color: rgb(128, 128, 128);
    display: inline-block;
    height: 0px;
    text-align: center;
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    width: 0px;
    column-rule-color: rgb(128, 128, 128);
    perspective-origin: 0px 0px;
    transform-origin: 0px 0px;
    caret-color: rgb(128, 128, 128);
    border: 0px none rgb(128, 128, 128);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(128, 128, 128) none 0px;
}/*.SPAN_36*/

.SPAN_36:after {
    color: rgb(128, 128, 128);
    text-align: center;
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    column-rule-color: rgb(128, 128, 128);
    caret-color: rgb(128, 128, 128);
    border: 0px none rgb(128, 128, 128);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(128, 128, 128) none 0px;
}/*.SPAN_36:after*/

.SPAN_36:before {
    color: rgb(128, 128, 128);
    text-align: center;
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    column-rule-color: rgb(128, 128, 128);
    caret-color: rgb(128, 128, 128);
    border: 0px none rgb(128, 128, 128);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(128, 128, 128) none 0px;
}/*.SPAN_36:before*/

.SPAN_40, .SPAN_46 {
    bottom: 62px;
    color: rgb(0, 0, 238);
    cursor: pointer;
    display: block;
    height: 0px;
    left: 15px;
    position: absolute;
    right: 35px;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    top: -12px;
    width: 0px;
    column-rule-color: rgb(0, 0, 238);
    perspective-origin: 0px 0px;
    transform-origin: 0px 0px;
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_40, .SPAN_46*/

.SPAN_40:after, .SPAN_46:after {
    color: rgb(0, 0, 238);
    cursor: pointer;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_40:after, .SPAN_46:after*/

.SPAN_40:before, .SPAN_46:before {
    color: rgb(0, 0, 238);
    cursor: pointer;
    text-align: center;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    list-style: none outside none;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_40:before, .SPAN_46:before*/

.A_49 {
    display: none;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    font: normal normal 400 normal 12px / 18px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
}/*.A_49*/

.A_49:after {
    text-size-adjust: 100%;
    font: normal normal 400 normal 12px / 18px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
}/*.A_49:after*/

.A_49:before {
    text-size-adjust: 100%;
    font: normal normal 400 normal 12px / 18px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
}/*.A_49:before*/

.SPAN_50 {
    background-position: -314px -236px;
    color: rgb(0, 0, 238);
    cursor: pointer;
    display: inline-block;
    height: 12px;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    vertical-align: -1px;
    width: 8px;
    column-rule-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    background: rgba(0, 0, 0, 0) url("https://kg.qq.com/gtimg/mediastyle/kge_v2/sprite/index.png?max_age=2592000&v=ee20b28d71165fb9fb637b091de95391") repeat scroll -314px -236px / auto padding-box border-box;
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_50*/

.SPAN_50:after {
    color: rgb(0, 0, 238);
    cursor: pointer;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_50:after*/

.SPAN_50:before {
    color: rgb(0, 0, 238);
    cursor: pointer;
    text-decoration: none solid rgb(0, 0, 238);
    text-size-adjust: 100%;
    column-rule-color: rgb(0, 0, 238);
    caret-color: rgb(0, 0, 238);
    border: 0px none rgb(0, 0, 238);
    font: normal normal 400 normal 0px / 0px FZLTXIHJW--GB1-0, "hiragino sans gb", "Microsoft YaHei", "Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    outline: rgb(0, 0, 238) none 0px;
}/*.SPAN_50:before*/
