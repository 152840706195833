#subscribe .select{
    background-color: #E8E6E6;
    border-radius: 28px;
    cursor: pointer;
}

#subscribe .selected{
    background-color: var(--color);
    border-radius: 28px;
    color: #E8E6E6;
    cursor: pointer;
}

#subscribe .select-charge{
    background-color: var(--color);
    border-radius: 28px;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
}