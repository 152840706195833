.item-1 {
    height: 100% !important;
}

.item-2 {
    height: 100% !important;
}

.item-download {
    width: 50%;
}

.carousel-caption {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0 !important;
    left: 0 !important;
}


.banner-default {
    background-color: var(--color);
    width: 100%;
    height: 25vw;
    padding-left: 10%;
    padding-right: 5%;
}

.banner-title {
    color: white;
    font-size: 200%;
}

.banner-description {
    color: white;
    font-size: 18px ;
}

@media (max-width: 1200px) {
    #content {
        padding-top: 55px;
    }
    /* .banner-title {
        font-size: 150%;
    }
    
    .banner-description {
        font-size: 14px ;
    } */

    .item-download {
        width: 40% !important;
    }
}

@media (max-width: 960px) {

    .banner-title {
        font-size: 150%;
    }
    
    .banner-description {
        font-size: 14px ;
    }

    .item-download {
        width: 45% !important;
    }
}

@media (max-width: 768px) {
    .banner-title {
        font-size: 100%;
    }
    
    .banner-description {
        font-size: 14px ;
    }

    .item-download {
        width: 45% !important;
    }
}

@media (max-width: 576px) {


    .banner-title {
        font-size: 80%;
    }
    
    .banner-description {
        font-size: 12px ;
    }

    .item-download {
        width: 45% !important;
    }
}

@media (max-width: 350px) {
    .banner-title {
        font-size: 80%;
    }
    
    .banner-description {
        font-size: 9px !important;
    }

    .item-download {
        width: 45% !important;
    }
}
