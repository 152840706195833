.btn {
    /* background-color: #007bff;
    color: black; */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.btn:hover {
    background-color: #0056b3;
}

.btn-active {
    background-color: #28a745;
}

.btn-toggle-view {
    float: right;
    margin-top: -55px;
    margin-right: 10px;
    border-radius: 5px;
    border: 2px solid #007bff;
    padding: 8px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    background: none;
    color: #007bff;
}

.btn-toggle-view.active {
    background: green;
    color: #000;
}

