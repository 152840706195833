#botlist, #commentlist, .bot-commentSection {
    height: 100vh;
    overflow-y: auto;
    border: 1px solid #ddd; 
    border-top: 0px !important;
    scrollbar-width: none; 
    -ms-overflow-style: none;
}

.bot-commentSection {
    font-size: 14px;
    line-height: 1.358;
    word-break: break-word;
    word-wrap: break-word;
}

#botlist, #commentlist
{
    max-width: 240px;
}

#botlist::-webkit-scrollbar, #commentlist::-webkit-scrollbar, .bot-commentSection::-webkit-scrollbar {
    display: none;
}

.custom-column {
    padding-top: 0px;
    max-width: 240px;
}

.custom-column-content {
    padding-top: 0px;
}

.bot-list-flex-container {
    display: flex;
    flex-wrap: wrap;
    width: 200px; 
    border-bottom: 1px solid #ddd; 
}

.flex-item-left {
    flex: 7 3 70%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: flex-start; 
    min-height: 48px;
}

.flex-item-right {
    position: relative;
    flex: 3 7 30%; 
    box-sizing: border-box; 
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 73.6px;
}

.avatar {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
}

.avatar-div {
    width: 70px;
    height: 70px;
}

.fa-circle-exclamation {
    position: absolute;
    top: 0px;
    right: 0px;
}

.uid-row, .name-row {
    width: 100%;
    text-align: center;
}

.flex-container:hover {
    cursor: pointer; 
}

.refresh-container {
    display: flex;
    justify-content: center; 
    align-items: center;    
    cursor: pointer;        
    margin-bottom: 10px;    
    transition: background-color 0.3s, color 0.3s; 
}

.refresh-container i {
    margin-right: 10px;     
}

.refresh-container:hover {
    color: red;             
}

.button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.button-container button {
    margin: 0 5px; 
    padding: 8px 16px; 
    font-size: 14px; 
    font-family: 'Roboto', sans-serif;
    border: none;
    border-radius: 4px; 
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.button-container button:hover {
    background-color: #0056b3;
    transform: translateY(-1px); 
}

.button-container button:active {
    background-color: #004085;
    transform: translateY(0); 
}


h5 {
    margin-bottom: 0px;
}

.avatar-div {
    padding: 0px !important;
}