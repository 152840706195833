.list-chat-bot-user {
  height: 696px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
}

.friends-list-bot {
  width: 250px !important;
  border-right: 1px solid #ccc;
}

.friends-list h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
}

.friend-item {
  margin-bottom: 10px;
  padding: 8px 12px;
  background-color: #f9f9f9;
  border-radius: 5px;
  cursor: pointer;
}

.chat-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-container {
  flex: 1;
  overflow-y: auto;
  /* padding: 10px; */
}

.message-item {
  margin-bottom: 10px;
  display: flex;
}

.user-message {
  justify-content: flex-end;
}

.assistant-message {
  justify-content: flex-start;
}

.message-content {
  background-color: #f1f0f0;
  padding: 8px 12px;
  border-radius: 15px;
  max-width: 70%;
}

.input-container {
  display: flex;
  padding: 10px;
  align-items: center;
}

.input-container input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ccc !important;
  border-radius: 20px !important;
}

.input-container .input-group-append {
  display: flex;
  align-items: center;
}

.input-container-bot button-bot {
  background-color: #ff0000;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  height: 50px;
  width: 75px;
}

.chat-header-bot {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  color: black;
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.hidden-header {
  display: none;
}

.target-message
{
  border-radius: 30px !important;
  background-color: #05c5de !important;
}

.my-message {
  border-radius: 30px !important;
}

.user-chat-name {
  font-weight: bold;
}

.active-bot {
  background-color: #05c5de !important;
}