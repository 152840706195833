.video-js .vjs-menu-button-inline.vjs-slider-active,.video-js .vjs-menu-button-inline:focus,.video-js .vjs-menu-button-inline:hover,.video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 10em
}

.video-js .vjs-controls-disabled .vjs-big-play-button {
    display: none!important
}

.video-js .vjs-control {
    width: 3em
}

.video-js .vjs-menu-button-inline:before {
    width: 1.5em
}

.vjs-menu-button-inline .vjs-menu {
    left: 3em
}

.vjs-paused.vjs-has-started.video-js .vjs-big-play-button,.video-js.vjs-ended .vjs-big-play-button,.video-js.vjs-paused .vjs-big-play-button {
    display: block
}

.video-js .vjs-load-progress div,.vjs-seeking .vjs-big-play-button,.vjs-waiting .vjs-big-play-button {
    display: none!important
}

.video-js .vjs-mouse-display:after,.video-js .vjs-play-progress:after {
    padding: 0 .4em .3em
}

.video-js.vjs-ended .vjs-loading-spinner {
    display: none;
}

.video-js.vjs-ended .vjs-big-play-button {
    display: block !important;
}

video-js.vjs-ended .vjs-big-play-button,.video-js.vjs-paused .vjs-big-play-button,.vjs-paused.vjs-has-started.video-js .vjs-big-play-button {
    display: block
}



.video-js:hover .vjs-big-play-button,.video-js .vjs-big-play-button:focus,.video-js .vjs-big-play-button:active {
    background-color: rgba(179,106,118,0.9)
}

.video-js .vjs-loading-spinner {
    border-color:#f04b6a
}

.video-js .vjs-control-bar2 {
    background-color: transparent
}

.video-js .vjs-control-bar {
    background-color: transparent !important;
    color: #e86b6b;
    font-size: 20px
}


.video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin-left: -1em;
    margin-top: -1em;
    line-height: 2em;
    border: none !important;
    font-size: 3.5em;
    background: url('../images/play-icon@2x.png') no-repeat 0 0;
    background-size: contain;
    background-color:transparent !important;
    height: 2.53em !important;
    width: 3.18em !important;
    line-height: 1.9em !important;
    margin-top: -0.9em !important;
    margin-left: -1em;
    border-width: 0px;
    opacity: 0.8;
}

.video-js .vjs-play-progress:before,.video-js .vjs-progress-control .vjs-play-progress:before,.video-js .vjs-remaining-time,.video-js .vjs-volume-level:after,.video-js .vjs-volume-level:before,.video-js.vjs-live .vjs-time-control.vjs-current-time,.video-js.vjs-live .vjs-time-control.vjs-duration,.video-js.vjs-live .vjs-time-control.vjs-time-divider,.video-js.vjs-no-flex .vjs-time-control.vjs-remaining-time {
    display: none
}

.video-js .vjs-mouse-display {
    display:none !important;
}



.video-js .vjs-big-play-button .vjs-icon-placeholder:before{
    background: url('../images/play-icon@2x.png') no-repeat 0 0;
    background-size: contain;
}



.video-js:hover .vjs-big-play-button,.video-js .vjs-big-play-button:focus,.video-js .vjs-big-play-button:active {
    background: url('../images/play-icon@2x.png') no-repeat 0 0;
    background-size: contain;
}

.video-js .vjs-icon-play:before, .video-js .vjs-big-play-button:before {
    font-size: 40px;
}

.video-js  .vjs-progress-holder {
    font-size: 1.7em;
    border-radius: 10px;
}

.video-js .vjs-progress-holder .vjs-play-progress, .video-js .vjs-progress-holder .vjs-load-progress, .video-js .vjs-progress-holder .vjs-load-progress div, .video-js .vjs-slider,.vjs-volume-level {
    border-radius: 10px;
}

.video-js .vjs-load-progress {
    background: rgba(255,255,255,0.5);
}


.video-js .vjs-playback-rate {
    display:none;
}
