.wrap-top-record-home {
    border: 3px solid #ffcc00;
    border-radius: 13px;
    margin-bottom: 30px;
}

.wrap-top-record-home .list-top {
    padding: 20px;
    padding-top: 7px;
}

@media (max-width: 399px) {
    .wrap-top-record-home .list-top {
        padding: 0;
    }
}

.wrap-top-record-home .list-top.top-5 {
    border-right: 1px solid #b7b7b7;
}

@media (max-width: 991px) {
    .wrap-top-record-home .list-top.top-5 {
        border: none;
        padding-bottom: 0;
        margin-bottom: -7px;
    }
}

.wrap-top-record-home .list-top .title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 12px;
}

@media (max-width: 575px) {
    .wrap-top-record-home .list-top .title {
        font-size: 18px;
    }
}

.wrap-top-record-home .list-top .item {
    position: relative;
    padding: 15px 10px;
}

.wrap-top-record-home .list-top .item .number {
    position: absolute;
    left: 10px;
    top: 17px;
    font-size: 32px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
}

@media (max-width: 399px) {
    .wrap-top-record-home .list-top .item .number {
        top: 14px;
    }
}

.wrap-top-record-home .list-top .item .avatar {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 95px;
    top: 17px;
}

@media (max-width: 575px) {
    .wrap-top-record-home .list-top .item .avatar {
        display: none;
    }
}

.wrap-top-record-home .list-top .item .avatar img {
    width: 50px;
    height: 50px !important;
    object-fit: cover;
    border-radius: 50%;
}

.wrap-top-record-home .list-top .item.item_1 .number {
    width: 70px;
    height: 70px;
    background: url(../images/top1.svg) no-repeat;
    background-size: 100%;
    text-align: center;
    line-height: 70px;
    font-size: 25px;
    left: 6px;
    top: 8px;
}

@media (max-width: 399px) {
    .wrap-top-record-home .list-top .item.item_1 .number {
        width: 60px;
        height: 60px;
        line-height: 60px;
    }
}

.wrap-top-record-home .list-top .item.item_2 .number {
    background: url(../images/top2.svg) no-repeat;
    background-size: 100%;
    width: 55px;
    height: 55px;
    line-height: 55px;
    left: 13px;
}

.wrap-top-record-home .list-top .item.item_3 .number {
    background: url(../images/top3.svg) no-repeat;
    background-size: 100%;
    left: 15px;
}

@media (max-width: 399px) {
    .wrap-top-record-home .list-top .item.item_10 {
        margin-bottom: 15px;
    }
}

.wrap-top-record-home .list-top .item .phone {
    font-size: 20px;
    font-weight: bold;
    padding-left: 155px;
    display: block;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 575px) {
    .wrap-top-record-home .list-top .item .phone {
        padding-left: 98px;
        font-size: 15px;
    }
}

@media (max-width: 399px) {
    .wrap-top-record-home .list-top .item .phone {
        padding-left: 70px;
    }
}

.wrap-top-record-home .list-top .item .phone span {
    display: block;
    font-size: 15px;
    font-weight: 500;
}

.wrap-top-record-home .list-top .item .phone span .song_name {
    position: relative;
    display: inline-block;
}

.wrap-top-record-home .list-top .item .phone span .song_name img {
    width: 24px;
    position: absolute;
    right: -34px;
    top: 3px;
}

.wrap-top-record-home .list-top .item .phone:hover {
    color: var(--color);
}

.wrap-top-record-home .list-top .item .listen-count {
    position: absolute;
    right: 25px;
    font-weight: normal;
    top: 31px;
    font-size: 18px;
    display: none;
}

@media (max-width: 399px) {
    .wrap-top-record-home .list-top .item {
        padding: 10px;
    }
}