.flex-container {
  display: flex;       /* Sắp xếp các phần tử con theo hàng ngang */
  gap: 10px;  
  width:400px         /* Khoảng cách giữa các ô nhỏ */
}

.small-box {
  padding: 5px;        /* Khoảng đệm bên trong mỗi ô */
  background-color: #f0f0f0;
  border: 1px solid #000;
  text-align: center;  /* Canh giữa nội dung trong mỗi ô */
}
