
footer {
    background-color: var(--color);
    padding: 28px 0 40px;
    color: #fff;
    position: relative;
}

footer a {
    color: #FFFFFF;
}

footer a:hover {
    color: #FFFFFF;
}

footer .logo2 {
    margin: 0 0 21px;
    float: left;
}

footer .logo2 a {
    display: block;
    /* background: url(../images/yokara-logo-footer.png) no-repeat 0 0 transparent; */
    /* background-size: contain; */
    width: 180px;
    height: 36px;
    text-indent: -9999px;
    overflow: hidden;
}

footer .menu-bottom {
    float: right;
}

footer .menu-bottom ul {
    list-style-type: none;
    display: flex;
}

footer .menu-bottom ul li {
    margin-left: 20px;
}

footer .menu-bottom ul li:before {
    content: "|";
    padding-right: 20px;
}

footer .menu-bottom ul li:first-child {
    margin-left: 0px;
}

footer .menu-bottom ul li:first-child:before {
    content: "";
    padding-right: 0px;
}

footer .copyright .item {
    margin-bottom: 20px;
}

footer .copyright .item h4 {
    font-size: 18px;
}

footer .copyright .item p {
    margin: 0 0 5px;
    line-height: 120%;
}

footer .copyright .item p span {
    display: block;
}

@media (max-width: 640px) {
    footer .menu-bottom ul li {
        display: inline;
        margin-left: 5px;
    }
    footer .menu-bottom ul li:before {
        content: "|";
        padding-right: 5px;
    }
    footer .menu-bottom ul li:first-child {
        margin-left: 0px;
    }
    footer .menu-bottom ul li:first-child:before {
        content: "";
        padding-right: 0px;
    }
}