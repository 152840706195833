.list-chat-support-user {
  height: 649px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  margin-top: 80px;
  position: relative;
}

.friends-list {
  width: 400px;
  border-right: 1px solid #ccc;
}

.friends-list h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
}

.friend-item {
  margin-bottom: 10px;
  padding: 8px 12px;
  background-color: #f9f9f9;
  border-radius: 5px;
  cursor: pointer;
}

.chat-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-container {
  flex: 1;
  overflow-y: auto;
  /* padding: 10px; */
}

.message-item {
  margin-bottom: 10px;
  display: flex;
}

.user-message {
  justify-content: flex-end;
}

.assistant-message {
  justify-content: flex-start;
}

.message-content {
  background-color: #f1f0f0;
  padding: 8px 12px;
  border-radius: 15px;
  max-width: 70%;
}

.input-container {
  display: flex;
  padding: 10px;
  align-items: center;
}

.input-container input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ccc !important;
  border-radius: 20px !important;
}

.input-container .input-group-append {
  display: flex;
  align-items: center;
}

.input-container button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  height: 50px;
  width: 75px;
}

.input-container .file-input-label {
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  height: 50px;
  width: 50px;
  margin-right: 30px;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  color: #007bff;
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
}

.hidden-header {
  display: none;
}