.icon-download {
    width: 250px !important;
}

.head-banner {
    width: 150px;
}

.description {
    font-size: 18px !important;
}

.background {
    /* background: rgb(255,30,0);
    background: linear-gradient(90deg, rgba(255,30,0,0.8130602582830007) 5%, rgba(255,20,1,0.88) 50%, rgba(255,10,16,0.99) 95%); */
}

.icon-star {
    color: rgb(255, 217, 0);
}

.recording-yokara-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0px !important;
    padding-bottom: 20px;
    padding-left: 3.623% !important;
    padding-right: 15px !important;
}

.recording-yokara-info {
    display: flex;
}

.recording-yokarasvg {
    width: 70px;
    height: 70px;
    margin-right: 12px;
}

.recording-header-text {
    padding-top: 9px;
}

.recording-yokara-info h2 {
    margin-bottom: 0px;
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.32px;
}

.recording-yokara-info p {
    margin-bottom: 0px;
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 150% */
    letter-spacing: 0.32px;
}

.recording-download-button-button {
    padding: 0px;
    width: 106px;
    height: 33px;
    background-color: #F15350;
    border-radius: 16.5px;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.32px;
    border: 0px;
}

.recording-download-button {
    padding: 0px;
    padding-top: 5px;
    width: 106px;
    height: 33px;
    background-color: #F15350;
    border-radius: 16.5px;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.32px;
    border: 0px;
    text-decoration: none;
}

.recording-download-button:hover {
    text-decoration: none; /* Hoặc bạn có thể để là underline nếu muốn */
    color: #FFF; /* Màu khi hover */
}

.recording-download-button:focus {
    text-decoration: none;
}