.facebook-login {
    background-color: #0180C7 !important;
    border-radius: 50px !important;
    /* border-color: #0180C7 !important; */
}

.google-login {
    background-color: #FFFFFF !important;
    border-radius: 50px !important;
    border-color: #0180C7 !important;
}

.zalo-login {
    background-color: #0180C7 !important;
    border-radius: 50px !important;
    /* border-color: #0180C7; */
}

.uniid-login {
    background-color: #FA882C !important;
    border-radius: 50px !important;
    /* border-color: #0180C7; */
}

.phone-login {
    background-color: #0865A2 !important;
    border-radius: 50px !important;
    /* border-color: #0180C7; */
}

.login-icon {
    width: 30px;
    height: 30px;
}

.login-logo {
    /* background: linear-gradient(43.96deg, #F16041 7.31%, #F02C6A 95.13%); */
    width: 130px;
    height: 130px;
    border-radius: 20px;
}

.login-cancel{

}

.login-cancel span {
    cursor: pointer;
}

.login-cancel span:hover {
    color: #504949;
}