.message-item-img {
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
}
#si{
    background-color: red !important;
}
#lastmessage * {
    border-width: 2px;
    font-weight: bold;
}