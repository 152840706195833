/* Mixin Responsive*/
:root {
    --color: #ee4242;
    --color_disabled: #ee4242;
    --color_gradient: linear-gradient(270deg, #F09122 0%, #F06522 100%);
    --font: 'Roboto Condensed', sans-serif;
}


body {
    font-family: var(--font) !important;
    src: url("../fonts/NOTOSERIFLAO-BLACK.TTF");
    font-size: 14px;
}

a,
a:hover {
    text-decoration: none !important;
}


img {
    max-width: 100% !important;
    height: auto !important;
}

#content {
    padding-top: 90px;
    min-height: 600px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
    .container950 {
        max-width: 950px;
    }
}

@media (max-width: 992px) {
    .col-lg-20-percent {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

h1.heading_one {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    h1.heading_one {
        margin-top: 15px;
    }
}

.view_more {
    margin-top: 30px;
    margin-bottom: 70px;
}

@media (max-width: 767px) {
    .view_more {
        margin-top: 15px;
        margin-bottom: 40px;
    }
}

.view_more a {
    width: 100%;
    max-width: 300px;
    background: #e9e9e9;
    display: block;
    text-align: center;
    margin: auto;
    padding: 10px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.view_more a span {
    text-transform: uppercase;
    color: #737373;
    font-weight: bold;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.view_more a:hover {
    background-color: var(--color);
}

@media (max-width: 767px) {
    .view_more a:hover {
        border: none;
    }
}

.view_more a:hover span {
    color: #fff;
}

.page-error {
    min-height: 600px;
}

@media (max-width: 399px) {
    .page-error {
        font-size: 18px;
    }
}

.page-error .heading {
    font-size: 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin-top: 20px;
    color: #e41515;
}

.page-error p {
    font-size: 25px;
    color: #e41515;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

@media (max-width: 575px) {
    .page-error p {
        font-size: 20px;
    }
}

.page-error a.btn-yellow {
    display: inline-block;
    background: var(--color);
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
    border-radius: 7px;
    margin-top: 30px;
    width: 245px;
    text-align: center;
    position: relative;
}

.page-error a.btn-yellow i {
    font-size: 20px;
    margin-left: 10px;
    position: absolute;
    left: 0;
}

.page-login-fb-success {
    margin-top: 50px;
    min-height: 600px;
}

@media (max-width: 399px) {
    .page-login-fb-success {
        font-size: 18px;
    }
}

.page-login-fb-success img {
    width: 100px;
}

.page-login-fb-success .heading {
    font-size: 25px;
    font-weight: bold;
    margin-top: 20px;
    color: #000;
}

.page-login-fb-success p {
    font-size: 16px;
    font-weight: 500;
}

.page-login-fb-success a.btn-yellow {
    display: inline-block;
    background: var(--color);
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
    border-radius: 7px;
    margin-top: 30px;
    width: 245px;
    text-align: center;
    position: relative;
}

.page-login-fb-success a.btn-yellow i {
    font-size: 20px;
    margin-left: 10px;
    position: absolute;
    left: 0;
}

.pagination {
    float: right;
}

.pagination li a {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--color);
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.pagination li span {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--color);
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.pagination li:first-child a {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.pagination li.disabled span {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination li.active span {
    z-index: 1;
    color: #fff;
    background-color: var(--color);
    border-color: var(--color);
}

.avatar_user_vip {
    position: relative;
    padding-bottom: 100%;
    width: 100%;
    display: block;
    margin: auto;
}

.avatar_user_vip .icon_vip {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100% !important;
}

.avatar_user_vip .icon_vip img {
    position: relative;
    z-index: 9;
    border-radius: inherit !important;
}

.avatar_user_vip .icon_vip .bg-avatar {
    position: absolute;
    left: 3%;
    right: 3%;
    bottom: 3%;
    top: 3%;
    border-radius: 50%;
}

.avatar_user_vip>img {
    position: absolute;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100% !important;
    border-radius: 50%;
}

#slider_home {
    position: relative;
}

#slider_home .owl-dots {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
}

@media (max-width: 575px) {
    .list-song {
        margin-left: -7px;
        margin-right: -7px;
    }
    .list-song>.col,
    .list-song>[class*=col-] {
        padding-right: 7px;
        padding-left: 7px;
    }
}

.list-song .item {
    margin-bottom: 25px;
}

@media (max-width: 1199px) {
    .list-song .item.item-6 {
        display: none;
    }
}

@media (max-width: 1199px) {
    .list-song .item.item-7 {
        display: none;
    }
}

.list-song .item .images a {
    display: block;
    position: relative;
}

.list-song .item .images a .overlay {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(238, 66, 66, 0.4);
    transition: .5s ease;
    opacity: 0;
    z-index: 9;
}



.list-song .item .images a:hover .overlay {
    opacity: 1 !important;
}

.list-song .item .song-name {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 0;
}

@media (max-width: 575px) {
    .list-song .item .song-name {
        font-size: 18px;
    }
}
.list-song .item  a:hover .song-name{
    color: var(--color);
}
.list-song .item a:hover .singer_names  {
    color: var(--color);
}

.list-song .item  a .song-name {
    color: #000;
    font-size: 20px;
    font-weight: bold;
}

@media (max-width: 575px) {
    .list-song .item a .song-name  {
        font-size: 18px;
    }
}

.list-song .item a .singer_names  {
    font-size: 16px;
    color: #646464;
    margin-top: 5px;
}

@media (max-width: 575px) {
    .list-song .item a .singer_names  {
        font-size: 14px;
    }
}

.list-singer .item {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

@media (max-width: 1199px) {
    .list-singer .item.item-10 {
        display: none;
    }
}

@media (max-width: 991px) {
    .list-singer .item.item-10 {
        display: block;
    }
}

@media (max-width: 1199px) {
    .list-singer .item.item-11 {
        display: none;
    }
}

@media (max-width: 991px) {
    .list-singer .item.item-11 {
        display: block;
    }
}

.list-singer .item .image {
    position: relative;
    padding-bottom: 90%;
    width: 90%;
    display: block;
    margin: auto;
    transition: all .2s ease-in-out;
}

.list-singer .item .image .overlay {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(238, 66, 66, 0.4);
    transition: .5s ease;
    opacity: 0;
    z-index: 9;
    border-radius: 50%;
}

.list-singer .item .image:hover .overlay {
    opacity: 1 !important;
}

.list-singer .item .image img {
    position: absolute;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100% !important;
    border-radius: 50%;
}

.list-singer .item .singer_name {
    font-size: 20px;
    margin-top: 20px;
}

@media (max-width: 575px) {
    .list-singer .item .singer_name {
        font-size: 18px;
    }
}

.list-singer .item .singer_name a {
    color: #000;
    font-size: 20px;
    font-weight: bold;
}

@media (max-width: 575px) {
    .list-singer .item .singer_name a {
        font-size: 18px;
    }
}

.chuyenmuc .subtitle {
    font-size: 28px;
    margin: 25px 0;
}

@media (max-width: 575px) {
    .chuyenmuc .subtitle {
        font-size: 23px;
    }
}

.chuyenmuc .subtitle a {
    font-size: 28px;
    font-weight: 500;
    color: #000;
}
.chuyenmuc .subtitle a:hover {
    color:var(--color)
}

@media (max-width: 575px) {
    .chuyenmuc .subtitle a {
        font-size: 23px;
        font-weight: bold;
    }
}

.chuyenmuc .subtitle i {
    color: #b7b7b7;
    margin-left: 20px;
}



body.active {
    position: relative;
}

body.active::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9;
}

#openNav {
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 100px;
    padding-left: 15px;
    left: -300px;
}

@media (max-width: 575px) {
    #openNav {
        width: 100%;
        left: -100%;
    }
}

#openNav.active {
    left: 0;
}

#openNav .top {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
}

#openNav .top .avatar {
    width: 45px;
    height: 45px;
    margin-right: 15px;
}

#openNav .top .avatar img {
    width: 45px;
    height: 45px !important;
    object-fit: cover;
    border-radius: 50%;
}

#openNav .top .info h3 {
    margin: 0;
    font-size: 16px;
}

#openNav .top .info h3 a {
    font-size: 16px;
    color: #373737;
    font-weight: bold;
}

#openNav .top .info h3 p {
    font-size: 13px;
    color: #5b5b5b;
}

#openNav .list_link ul {
    padding-left: 0;
}

#openNav .list_link ul li {
    list-style: none;
    border-bottom: 1px solid #e8e8e8;
    padding: 15px;
}

#openNav .list_link ul li a {
    display: block;
}

#openNav .list_link ul li a span {
    color: #444444;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

#openNav .list_link ul li a span.icon {
    margin-right: 10px;
    padding-left: 0 !important;
}

#openNav .list_link ul li a span.icon img {
    width: 18px;
    height: 18px !important;
    object-fit: contain;
}

#openNav .list_link ul li a:hover span {
    padding-left: 10px;
}

#openNav .list_link ul li a .fa-angle-right {
    float: right;
    color: #444;
}

@media (max-width: 767px) {
    #openNav .list_link {
        display: block;
    }
}

#box-search-pc {
    position: fixed;
    top: 70px;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 99;
}

#box-search-pc .main {
    background-color: #e7e7e7;
    padding: 15px;
}

#box-search-pc .main h3 {
    font-size: 18px;
    font-weight: bold;
}

#box-search-pc .main input {
    border: none;
    border-radius: 30px;
    padding: 10px;
    padding-left: 55px;
    width: 100%;
    background: #fff url(../images/icon_search.png) no-repeat;
    background-size: 21px;
    background-position: 15px 10px;
}

#box-search-pc .main input:focus {
    outline: none;
}

.genre-page {
    min-height: 600px;
}

@media (max-width: 575px) {
    .genre-page .list-genre {
        margin-left: -7px;
        margin-right: -7px;
    }
    .genre-page .list-genre>.col,
    .genre-page .list-genre>[class*=col-] {
        padding-right: 7px;
        padding-left: 7px;
    }
}

.genre-page .list-genre .item {
    margin-bottom: 25px;
}

@media (max-width: 1199px) {
    .genre-page .list-genre .item.item-6 {
        display: none;
    }
}

@media (max-width: 1199px) {
    .genre-page .list-genre .item.item-7 {
        display: none;
    }
}

.genre-page .list-genre .item .images a {
    display: block;
    position: relative;
}

.genre-page .list-genre .item .images a .overlay {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(238, 66, 66, 0.4);
    transition: .5s ease;
    opacity: 0;
    z-index: 9;
}

.genre-page .list-genre .item .images a:hover .overlay {
    opacity: 1 !important;
}

.genre-page .list-genre .item .song-name {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 0;
}

@media (max-width: 575px) {
    .genre-page .list-genre .item .song-name {
        font-size: 18px;
    }
}

.genre-page .list-genre .item .song-name a {
    color: #000;
    font-size: 20px;
    font-weight: bold;
}

@media (max-width: 575px) {
    .genre-page .list-genre .item .song-name a {
        font-size: 18px;
    }
}

.genre-page .list-genre .item .singer_names a {
    font-size: 16px;
    color: #646464;
    margin-top: 5px;
}

@media (max-width: 575px) {
    .genre-page .list-genre .item .singer_names a {
        font-size: 14px;
    }
}

.detail-singer-page {
    min-height: 600px;
}

.collection-page .list-collection .item a.image-wrapper {
    display: block;
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
    float: left;
    margin-bottom: 15px;
}

.collection-page .list-collection .item a.image-wrapper img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100% !important;
}

.song-detail-page .info-song .top .state_video {
    margin-top: 12px;
    margin-bottom: 12px;
}

.song-detail-page .info-song .top .state_video span {
    display: block;
    text-align: center;
    font-size: 16px;
}

.song-detail-page .info-song .top .button {
    width: 100%;
    text-align: center;
    background: #1cbc14;
    height: 42px;
    border-radius: 7px;
}

.song-detail-page .info-song .top .button button {
    background: none;
    border: none;
    height: 40px;
    padding-top: 5px;
    width: 100%;
    outline: none;
}

.song-detail-page .info-song .top .button button img {
    height: 30px !important;
}

.song-detail-page .info-song .top .button button span {
    font-size: 16px;
    color: #fff;
    margin-left: 5px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
}

.song-detail-page .info-song .middle .song-name {
    font-size: 23px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
}

.song-detail-page .info-song .middle .singer-name {
    font-size: 17px;
    color: #010101;
}

.song-detail-page .info-song .middle .singer-name a {
    color: #010101;
}

.song-detail-page .info-song .middle .view-count {
    color: #646464;
    font-size: 15px;
}

.song-detail-page .info-song .download-app .title {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 20px;
}

.song-detail-page .info-song .download-app .list {
    padding-left: 0;
}

.song-detail-page .info-song .download-app .list li {
    list-style: none;
    display: inline-block;
    width: 200px;
    margin-right: 20px;
}

@media (max-width: 575px) {
    .song-detail-page .info-song .download-app .list li {
        width: 150px;
    }
}

@media (max-width: 330px) {
    .song-detail-page .info-song .download-app .list li {
        width: 135px;
        margin-right: 5px;
    }
}

.song-sidebar .heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.song-sidebar .item {
    margin-bottom: 15px;
}

@media (max-width: 991px) {
    .song-sidebar .item .info {
        margin-top: 7px;
    }
}

@media (max-width: 767px) {
    .song-sidebar .item .info {
        padding-left: 0;
    }
}

.song-sidebar .item .info .title {
    position: relative;
    line-height: 1 !important;
    font-size: 15px;
}

.song-sidebar .item .info .title a {
    font-size: 16px;
    color: #000;
    display: block;
    font-weight: bold;
}

.song-sidebar .item .info .title img {
    width: 25px;
    margin-top: 5px;
}

.song-sidebar .item .info .singer_names {
    margin-bottom: 0;
}

.song-sidebar .item .info .singer_names a {
    color: #767676;
    font-size: 15px;
}

.song-sidebar .item .info .view_num {
    color: #767676;
    margin-bottom: 0;
}

.song-sidebar .item .info .view_num i {
    margin-right: 5px;
}

.song-sidebar .view_more a {
    max-width: 100%;
}

.record-detail-page .item-record .video-play .processing-file {
    position: relative;
}

.record-detail-page .item-record .video-play .processing-file .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding-top: 20%;
    background: rgba(0, 0, 0, 0.52);
}

.record-detail-page .item-record .video-play .processing-file .icon i {
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    font-size: 70px;
    color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 991px) {
    .record-detail-page .item-record .video-play .processing-file .icon i {
        font-size: 50px;
    }
}

.record-detail-page .item-record .video-play .processing-file .icon p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    line-height: 110%;
    margin-top: 10px;
}

@media (max-width: 991px) {
    .record-detail-page .item-record .video-play .processing-file .icon p {
        font-size: 15px;
    }
}

.record-detail-page .item-record .video-play .processing-file .progress {
    position: relative;
    border-radius: 0;
    height: 10px;
}

@media (max-width: 991px) {
    .record-detail-page .item-record .video-play .processing-file .progress {
        height: 8px;
    }
}

.record-detail-page .item-record .video-play .processing-file .progress-bar-striped {
    background-color: #2fc224;
}

.record-detail-page .info-record .account {
    padding-bottom: 7px;
    margin-top: 25px;
    position: relative;
}

.record-detail-page .info-record .account .avatar {
    width: 45px;
    height: 45px;
    margin-right: 15px;
}

.record-detail-page .info-record .account .avatar img {
    width: 45px;
    height: 45px !important;
    object-fit: cover;
    border-radius: 50%;
}

.record-detail-page .info-record .account .info h3 {
    margin: 0;
    font-size: 16px;
}

.record-detail-page .info-record .account .info h3 a {
    font-size: 16px;
    color: #373737;
    font-weight: bold;
}

.record-detail-page .info-record .account .info p {
    font-size: 13px;
    color: #767676;
}

.record-detail-page .info-record .middle .record-name {
    font-size: 23px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
}

.record-detail-page .info-record .middle .view-count {
    color: #646464;
    font-size: 15px;
}

.record-detail-page .info-record .download-app .title {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 20px;
}

.record-detail-page .info-record .download-app .list {
    padding-left: 0;
}

.record-detail-page .info-record .download-app .list li {
    list-style: none;
    display: inline-block;
    width: 200px;
    margin-right: 20px;
}

@media (max-width: 575px) {
    .record-detail-page .info-record .download-app .list li {
        width: 150px;
    }
}

@media (max-width: 330px) {
    .record-detail-page .info-record .download-app .list li {
        width: 135px;
        margin-right: 5px;
    }
}

.record-sidebar .heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.record-sidebar .item {
    margin-bottom: 15px;
}

@media (max-width: 991px) {
    .record-sidebar .item .info {
        margin-top: 7px;
    }
}

@media (max-width: 767px) {
    .record-sidebar .item .info {
        padding-left: 0;
    }
}

.record-sidebar .item .info .title {
    position: relative;
    line-height: 1 !important;
    font-size: 15px;
}

.record-sidebar .item .info .title a {
    font-size: 16px;
    color: #000;
    display: block;
    font-weight: bold;
}

.record-sidebar .item .info .title img {
    width: 25px;
    margin-top: 5px;
}

.record-sidebar .item .info .singer_names {
    margin-bottom: 0;
}

.record-sidebar .item .info .singer_names a {
    color: #767676;
    font-size: 15px;
}

.record-sidebar .item .info .view_num {
    color: #767676;
    margin-bottom: 0;
}

.record-sidebar .item .info .view_num i {
    margin-right: 5px;
}

.record-sidebar .view_more a {
    max-width: 100%;
}

.record-sidebar .item {
    margin-bottom: 15px;
}

.record-sidebar .item .title {
    position: relative;
    line-height: 1 !important;
    font-size: 15px;
}

.record-sidebar .item .title a {
    font-size: 15px;
    color: #000;
    display: block;
}

.record-sidebar .item .title img {
    width: 25px;
    margin-top: 5px;
}

.record-sidebar .item img.icon-camera {
    width: 25px;
}

.record-sidebar .item .view_num {
    color: #767676;
    margin-bottom: 0;
}

.record-sidebar .item .view_num i {
    margin-right: 5px;
}

.record-sidebar .view_more a {
    max-width: 100%;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}



.top-record-page .heading1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 20px;
}

.top-record-page .icon-heading {
    text-align: center;
    color: #ff1d1d;
    margin-top: 10px;
}

.top-record-page .icon-heading i {
    font-size: 17px;
}

.top-record-page .icon-heading i:nth-of-type(2) {
    font-size: 30px;
}

.top-record-page .list-top {
    padding: 20px;
    padding-top: 7px;
}

@media (max-width: 399px) {
    .top-record-page .list-top {
        padding: 0;
    }
}

.top-record-page .list-top.top-5 {
    border-right: 1px solid #b7b7b7;
}

@media (max-width: 991px) {
    .top-record-page .list-top.top-5 {
        border: none;
        padding-bottom: 0;
        margin-bottom: -7px;
    }
}

.top-record-page .list-top .title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 12px;
}

@media (max-width: 575px) {
    .top-record-page .list-top .title {
        font-size: 18px;
    }
}

.top-record-page .list-top .item {
    position: relative;
    padding: 15px 10px;
}

.top-record-page .list-top .item .number {
    position: absolute;
    left: 10px;
    top: 17px;
    font-size: 32px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
}

@media (max-width: 399px) {
    .top-record-page .list-top .item .number {
        top: 14px;
    }
}

.top-record-page .list-top .item .avatar {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 95px;
    top: 17px;
}

@media (max-width: 575px) {
    .top-record-page .list-top .item .avatar {
        display: none;
    }
}

.top-record-page .list-top .item .avatar img {
    width: 50px;
    height: 50px !important;
    object-fit: cover;
    border-radius: 50%;
}

.top-record-page .list-top .item.item_1 .number {
    width: 70px;
    height: 70px;
    background: url(../images/top1.svg) no-repeat;
    background-size: 100%;
    text-align: center;
    line-height: 70px;
    font-size: 25px;
    left: 6px;
    top: 8px;
}

@media (max-width: 399px) {
    .top-record-page .list-top .item.item_1 .number {
        width: 60px;
        height: 60px;
        line-height: 60px;
    }
}

.top-record-page .list-top .item.item_2 .number {
    background: url(../images/top2.svg) no-repeat;
    background-size: 100%;
    width: 55px;
    height: 55px;
    line-height: 55px;
    left: 13px;
}

.top-record-page .list-top .item.item_3 .number {
    background: url(../images/top3.svg) no-repeat;
    background-size: 100%;
    left: 15px;
}

@media (max-width: 399px) {
    .top-record-page .list-top .item.item_10 {
        margin-bottom: 15px;
    }
}

.top-record-page .list-top .item .phone {
    font-size: 20px;
    font-weight: bold;
    padding-left: 155px;
    display: block;
    color: #333;
}

@media (max-width: 575px) {
    .top-record-page .list-top .item .phone {
        padding-left: 98px;
        font-size: 15px;
    }
}

@media (max-width: 399px) {
    .top-record-page .list-top .item .phone {
        padding-left: 70px;
    }
}

.top-record-page .list-top .item .phone span {
    display: block;
    font-size: 15px;
    font-weight: 500;
}

.top-record-page .list-top .item .phone span .song_name {
    position: relative;
    display: inline-block;
}

.top-record-page .list-top .item .phone span .song_name img {
    width: 24px;
    position: absolute;
    right: -34px;
    top: 3px;
}

.top-record-page .list-top .item .phone:hover {
    color: var(--color);
}

.top-record-page .list-top .item .listen-count {
    position: absolute;
    right: 25px;
    font-weight: normal;
    top: 31px;
    font-size: 18px;
    display: none;
}

.top-record-page .list-top .item {
    border-top: 1px solid #dcdcdc;
}

.sidebar-account .top {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
}

.sidebar-account .top .avatar {
    width: 45px;
    height: 45px;
    margin-right: 15px;
}

.sidebar-account .top .avatar>a img {
    width: 45px;
    height: 45px !important;
    object-fit: cover;
    border-radius: 50%;
}

.sidebar-account .top .info h3 {
    margin: 0;
    font-size: 16px;
}

.sidebar-account .top .info h3 a {
    font-size: 16px;
    color: #373737;
    font-weight: bold;
}

.sidebar-account .top .info h3 p {
    font-size: 13px;
    color: #5b5b5b;
}

.sidebar-account .list_link ul {
    padding-left: 0;
}

.sidebar-account .list_link ul li {
    list-style: none;
    border-bottom: 1px solid #e8e8e8;
    padding: 15px;
}

.sidebar-account .list_link ul li a {
    display: block;
}

.sidebar-account .list_link ul li a span {
    color: #444444;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.sidebar-account .list_link ul li a span.icon {
    color: var(--color) !important;
    margin-right: 10px;
    padding-left: 0 !important;
}

.sidebar-account .list_link ul li a span.icon img {
    width: 18px;
    height: 18px !important;
    object-fit: contain;
}

.sidebar-account .list_link ul li a:hover span {
    padding-left: 10px;
}

.sidebar-account .list_link ul li a .fa-angle-right {
    float: right;
    color: #444;
}

@media (max-width: 767px) {
    .sidebar-account .list_link {
        display: none;
    }
}

.main-account .content-account {
    width: 100%;
    max-width: 385px;
    margin: auto;
    padding-bottom: 200px;
    min-height: 600px;
}

.main-account .content-account .heading-account {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
}

@media (max-width: 991px) {
    .main-account .content-account .heading-account {
        margin-top: 40px;
    }
}

.main-account .content-account .alert {
    color: #fff;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
}

.main-account .content-account .alert.alert-success {
    background: #1bcd1f;
}

.main-account .content-account .alert.alert-error {
    background: #fb3131;
}

.main-account .content-account form button {
    background: var(--color) !important;
    width: 100%;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
}

.main-login .logo {
    display: block;
    text-align: center;
    padding-top: 50px;
}

.main-login .logo img {
    max-width: 260px !important;
}

.main-login .content-login {
    width: 100%;
    max-width: 400px;
    padding: 30px;
    margin: 30px auto 200px;
    border-radius: 7px;
    box-shadow: 0px 2px 7px 5px rgba(178, 177, 177, 0.45);
}

.main-login .content-login .formLogin h1 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
    margin-top: 15px;
}

.main-login .content-login .formLogin button {
    /* background: var(--color) !important; */
    width: 100%;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
    display: block;
    text-align: center;
}

.main-login .content-login .formLogin .login-facebook {
    background: #3e57c3 !important;
    border-radius: 5px;
    position: relative;
    margin-top: 15px;
}

.main-login .content-login .formLogin .login-facebook i {
    color: #fff;
    font-size: 35px;
    position: absolute;
    left: 7px;
    bottom: -3px;
}

@media (max-width: 767px) {
    .main-login .content-login .formLogin .login-facebook i {
        font-size: 25px;
    }
}

.main-login .content-login .formLogin .login-facebook span {
    color: #fff;
    display: block;
}

.main-login .content-login .forget-pass {
    display: block;
    text-align: center;
    margin-top: 15px;
}

.main-login .content-login .forget-pass span {
    color: #5c5c5c;
    border-bottom: 1px solid #5c5c5c;
}

.change-info {
    padding-bottom: 200px;
}

.change-info .heading1 {
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
}

@media (max-width: 767px) {
    .change-info .heading1 {
        background: #f3f3f3;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 0;
        margin-left: -15px;
        margin-right: -15px;
    }
}

.change-info .alert {
    color: #fff;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: auto;
}

.change-info .alert.alert-success {
    background: #1bcd1f;
}

.change-info .alert.alert-error {
    background: #fb3131;
}

.change-info form {
    margin-top: 50px;
}

.change-info form .button .btn {
    background: var(--color);
    color: #fff;
    text-transform: uppercase;
    width: 80%;
    max-width: 300px;
    margin-top: 15px;
}

.btnrecheck {
    background: var(--color);
    color: #fff;
    text-transform: uppercase;
    width: 80%;
    max-width: 300px;
}

.change-info .wrap {
    border: 1px solid #eaeaea;
}

@media (max-width: 767px) {
    .change-info .wrap {
        border: none;
    }
}

.change-info .wrap .left {
    height: 100%;
    border-right: 1px solid #eaeaea;
    padding: 30px;
}

@media (max-width: 767px) {
    .change-info .wrap .left {
        border-right: 0;
        border-bottom: 1px solid #eaeaea;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
        border-right: none;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }
}

.change-info .wrap .left .avatar {
    text-align: center;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .change-info .wrap .left .avatar {
        float: left;
        margin-right: 30px;
        min-width: 78px;
    }
}

.change-info .wrap .left .avatar img {
    width: 99px;
    height: 99px !important;
    object-fit: cover;
    border-radius: 50%;
}

@media (max-width: 767px) {
    .change-info .wrap .left .custom-file {
        width: 60%;
        margin-top: 30px;
    }
}

.change-info .wrap .left .custom-file .custom-file-label {
    background-color: #e4e4e4;
    text-align: center;
    white-space: nowrap;
}

.change-info .wrap .left .custom-file .custom-file-label::after {
    content: '';
    padding: 0;
    border-left: 0;
}

.change-info .wrap .right .item {
    display: -ms-flexbox !important;
    display: flex !important;
    border-bottom: 1px solid #eaeaea;
    margin-left: -30px;
    padding: 10px 10px 10px 30px;
    position: relative;
}

@media (max-width: 767px) {
    .change-info .wrap .right .item {
        margin-left: 0;
        padding-left: 15px;
        margin-right: -15px;
    }
}

.change-info .wrap .right .item:last-child {
    border: none;
}

.change-info .wrap .right .item .label {
    min-width: 30%;
    font-weight: bold;
    padding-top: 10px;
}

.change-info .wrap .right .item input {
    height: 35px;
    width: 100%;
    margin-right: 35px;
    border: none;
    background: #fff;
}

.change-info .wrap .right .item input:focus {
    outline: none;
}

.change-info .wrap .right .item .icon {
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
}

.change-info .wrap .right .item .icon i {
    color: #8d8d8d;
    font-size: 20px;
}

.term-page .content h1 {
    font-weight: bold;
    font-size: 25px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
}

.term-page .content h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 25px;
    color: rgba(238, 66, 66, 0.9);
}

.term-page .content h3 {
    font-size: 18px;
    /* font-weight: bold; */
    margin-top: 25px;
    /* color: rgba(238, 66, 66, 0.9); */
}

.term-page .content p {
    font-size: 16px;
    color: #444;
}

.user-detail-page .top {
    background: #f8f8f8;
    padding-top: 30px;
    padding-bottom: 30px;
}

.user-detail-page .top .wrap-top .avatar {
    width: 150px;
    height: 150px;
    min-width: 150px;
    margin-right: 30px;
    cursor: pointer;
}

.user-detail-page .top .wrap-top .avatar>img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    border-radius: 50%;
}

@media (max-width: 399px) {
    .user-detail-page .top .wrap-top .avatar {
        width: 120px;
        height: 120px !important;
        min-width: 120px;
        margin-right: 20px;
    }
}

@media (max-width: 330px) {
    .user-detail-page .top .wrap-top .avatar {
        width: 100px;
        height: 100px !important;
        min-width: 100px;
        margin-right: 10px;
    }
}

.user-detail-page .top .wrap-top .info h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 2px;
}

@media (max-width: 330px) {
    .user-detail-page .top .wrap-top .info h1 {
        font-size: 26px;
    }
}

.user-detail-page .top .wrap-top .info p {
    font-size: 16px;
    margin-bottom: 0;
}

.user-detail-page .list-record {
    margin-top: 30px;
}

.user-detail-page .list-record h2 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 2px;
}

.user-detail-page .list-record .total-record {
    font-size: 16px;
    margin-bottom: 20px;
}

.user-detail-page .list-record .item .title {
    padding-right: 0;
}

.user-detail-page .list-record {
    min-height: 700px;
}

.user-detail-page .list-record .heading-record {
    background: #f3f3f3;
    border-top: 2px solid #e8e8e8;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -1px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .user-detail-page .list-record .heading-record {
        background: none;
        border: none;
    }
}

.user-detail-page .list-record .item {
    margin-bottom: 15px;
}

@media (max-width: 767px) {
    .user-detail-page .list-record .item .info {
        padding-left: 0;
    }
}

.user-detail-page .list-record .item .info .title {
    position: relative;
    padding-right: 30px;
    line-height: 0.8;
}

.user-detail-page .list-record .item .info .title a {
    font-size: 16px;
    color: #000;
}

.user-detail-page .list-record .item .info .title img {
    width: 25px;
}

.user-detail-page .list-record .item .info .title .dropdown-record {
    position: absolute;
    top: 7px;
    right: 0;
    font-size: 16px;
    color: var(--color);
    width: 20px;
    cursor: pointer;
    text-align: center;
}

.user-detail-page .list-record .item .info .title .dropdown-record .icon {
    display: inline-block;
    width: 100%;
}

.user-detail-page .list-record .item .info .title .dropdown-record .dropdown-menu {
    top: 21px !important;
    right: -110px !important;
    left: auto !important;
    margin-top: 0;
    margin-right: .125rem;
    min-width: 100px !important;
}

.user-detail-page .list-record .item .info .title .dropdown-record .dropdown-menu .dropdown-item {
    padding: 6px;
    text-align: right;
}

.user-detail-page .list-record .item .info .title .dropdown-record .dropdown-menu .dropdown-item.active,
.user-detail-page .list-record .item .info .title .dropdown-record .dropdown-menu .dropdown-item:active {
    background-color: var(--color);
    color: #fff;
}

.user-detail-page .list-record .item .mp4-has-image {
    display: block;
}

.user-detail-page .list-record .item .mp4-has-image img {
    opacity: 0;
}

.user-detail-page .list-record .item .view_num {
    color: #767676;
}

.user-detail-page .list-record .item .view_num i {
    margin-right: 5px;
}

.user-detail-page .item-record .video-play {
    position: relative;
}

.user-detail-page .item-record .video-play .video-player-ads {
    position: absolute;
    bottom: 45px;
    left: 30px;
    right: 30px;
}

@media (max-width: 767px) {
    .user-detail-page .item-record .video-play .video-player-ads {
        position: static;
    }
}

.user-detail-page .item-record .video-play .video-player-ads a {
    display: block;
    position: relative;
}

.user-detail-page .item-record .video-play .video-player-ads a span {
    position: absolute;
    top: -2px;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    z-index: 99;
}

@media (max-width: 767px) {
    .user-detail-page .item-record .video-play .video-player-ads a span {
        display: none;
    }
}

.user-detail-page .item-record .name-record {
    font-size: 24px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 0;
}

.user-detail-page .item-record .view_num {
    font-size: 16px;
    color: #767676;
    margin-bottom: 7px;
}

.user-detail-page .item-record .send-record p {
    font-weight: bold;
    margin-bottom: 0;
}

.user-detail-page .item-record .send-record a {
    display: block;
    color: #4f4f4f;
}

.user-detail-page .item-record .send-record a:hover {
    color: var(--color);
}

.user-detail-page .item-record .send-record button {
    background: #e93636;
    border: none;
    width: 100%;
    max-width: 300px;
    height: 42px;
    line-height: 42px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    position: relative;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.user-detail-page .item-record .send-record button i {
    position: absolute;
    left: 10px;
    top: 13px;
    font-size: 19px;
}

.user-detail-page .item-record .success p {
    font-weight: normal;
}

.user-detail-page .item-record .success p.link {
    font-weight: bold;
}

.user-detail-page .item-record .success p.ok {
    background: #f2f2f2;
    padding: 5px 10px;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 7px !important;
}

.user-detail-page .item-record .success p.ok i {
    color: #2fc224;
    margin-right: 10px;
    font-size: 15px;
}

.user-detail-page .item-record .success p.rank {
    display: block;
    margin-bottom: 10px !important;
    padding-left: 10px;
}

.user-detail-page .item-record .success p.rank img {
    width: 15px;
    margin-right: 6px;
}

.user-detail-page .item-record .success p.rank span {
    font-weight: bold;
    color: #e00e0e;
}

.user-detail-page .item-record .success p a {
    display: inline;
    font-weight: normal;
    color: #0067bf;
}

.user-detail-page .item-record .account {
    padding-bottom: 7px;
    margin-top: 25px;
    position: relative;
}

@media (max-width: 575px) {
    .user-detail-page .item-record .account {
        padding-bottom: 30px;
    }
}

.user-detail-page .item-record .account .avatar {
    width: 45px;
    height: 45px;
    margin-right: 15px;
}

.user-detail-page .item-record .account .avatar img {
    width: 45px;
    height: 45px !important;
    object-fit: cover;
    border-radius: 50%;
}

.user-detail-page .item-record .account .info h3 {
    margin: 0;
    font-size: 16px;
}

.user-detail-page .item-record .account .info h3 a {
    font-size: 16px;
    color: #373737;
    font-weight: bold;
}

.user-detail-page .item-record .account .info p {
    font-size: 13px;
    color: #767676;
}

.user-detail-page .item-record .account .btn-share-fb {
    position: absolute;
    right: 0;
}

@media (max-width: 575px) {
    .user-detail-page .item-record .account .btn-share-fb {
        bottom: 0;
        left: 0;
    }
}

.user-detail-page .item-record .upgrade-vip-btn {
    margin-bottom: 20px;
}

.user-detail-page .item-record .upgrade-vip-btn a {
    display: inline-block;
    background: #ffe375;
    background: -moz-linear-gradient(top, #ffe375 0%, #f4b556 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffe375), color-stop(100%, #f4b556));
    background: -webkit-linear-gradient(top, #ffe375 0%, #f4b556 100%);
    background: -o-linear-gradient(top, #ffe375 0%, #f4b556 100%);
    background: -ms-linear-gradient(top, #ffe375 0%, #f4b556 100%);
    background: linear-gradient(to bottom, #ffe375 0%, #f4b556 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffe375', endColorstr='#f4b556', GradientType=0);
    padding: 7px 12px;
    max-width: 160px;
    border-radius: 10px;
}

@media (max-width: 399px) {
    .user-detail-page .item-record .upgrade-vip-btn a {
        max-width: 100%;
    }
}

.user-detail-page .item-record .upgrade-vip-btn a img {
    width: 30px;
    height: 30px !important;
    min-width: 30px;
    margin-right: 12px;
}

.user-detail-page .item-record .upgrade-vip-btn a span {
    font-weight: bold;
    color: #000;
    font-size: 15px;
}

.user-detail-page .item-record .place {
    border-top: 1px solid #e8e8e8;
    margin-top: 15px;
    margin-bottom: 5px;
    padding-top: 15px;
}

.user-detail-page .item-record .place span {
    font-weight: bold;
}

.user-detail-page .item-record .share h4 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 12px;
}

.user-detail-page .item-record .share ul {
    padding-left: 0;
}

.user-detail-page .item-record .share ul li {
    list-style: none;
    display: inline-block;
    margin-right: 15px;
    width: 160px;
    height: 42px;
    position: relative;
}

@media (max-width: 767px) {
    .user-detail-page .item-record .share ul li {
        margin-right: 5px;
        margin-bottom: 10px;
        width: 30%;
        height: 30px;
        padding-left: 20px;
        float: left;
    }
}

.user-detail-page .item-record .share ul li.facebook {
    background: #3e57c3;
    border-radius: 5px;
}

.user-detail-page .item-record .share ul li.facebook i {
    color: #fff;
    font-size: 35px;
    position: absolute;
    left: 7px;
    bottom: -3px;
}

@media (max-width: 767px) {
    .user-detail-page .item-record .share ul li.facebook i {
        font-size: 25px;
    }
}

.user-detail-page .item-record .share ul li.download {
    background: #2fc224;
    border-radius: 5px;
}

.user-detail-page .item-record .share ul li.download i {
    color: #fff;
    font-size: 29px;
    position: absolute;
    left: 7px;
    bottom: 5px;
}

@media (max-width: 767px) {
    .user-detail-page .item-record .share ul li.download i {
        font-size: 20px;
    }
}

.user-detail-page .item-record .share ul li.zalo {
    background: #1c9aea;
    border-radius: 5px;
}

.user-detail-page .item-record .share ul li.zalo i {
    color: #fff;
    font-size: 29px;
    position: absolute;
    left: 7px;
    bottom: 5px;
    width: 27px;
    height: 27px;
    background: url(../images/icon-zalo.png);
    background-size: 20px;
    background-repeat: no-repeat;
}

@media (max-width: 767px) {
    .user-detail-page .item-record .share ul li.zalo i {
        position: absolute;
        left: 7px;
        bottom: -1px;
        width: 23px;
        height: 25px;
        background-size: 15px;
    }
}

.user-detail-page .item-record .share ul li a {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 42px;
    color: #fff;
    font-size: 15px;
}

@media (max-width: 767px) {
    .user-detail-page .item-record .share ul li a {
        font-size: 13px;
        line-height: 30px;
    }
}

.user-detail-page .processing-file {
    position: relative;
}

.user-detail-page .processing-file .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding-top: 20%;
    background: rgba(0, 0, 0, 0.52);
}

.user-detail-page .processing-file .icon i {
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    font-size: 70px;
    color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 991px) {
    .user-detail-page .processing-file .icon i {
        font-size: 50px;
    }
}

.user-detail-page .processing-file .icon p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    line-height: 110%;
    margin-top: 10px;
}

@media (max-width: 991px) {
    .user-detail-page .processing-file .icon p {
        font-size: 15px;
    }
}

.user-detail-page .processing-file .progress {
    position: relative;
    border-radius: 0;
    height: 10px;
}

@media (max-width: 991px) {
    .user-detail-page .processing-file .progress {
        height: 8px;
    }
}

.user-detail-page .processing-file .progress-bar-striped {
    background-color: #2fc224;
}

.user-detail-page .unpublic-file {
    position: relative;
}

.user-detail-page .unpublic-file .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding-top: 20%;
    background: rgba(0, 0, 0, 0.52);
}

@media (max-width: 991px) {
    .user-detail-page .unpublic-file .icon {
        padding-top: 13%;
    }
}

.user-detail-page .unpublic-file .icon i {
    font-size: 70px;
    color: rgba(255, 255, 255, 0.8);
}

.user-detail-page .unpublic-file .icon p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    line-height: 110%;
    margin-top: 10px;
}

.user-detail-page .unpublic-file .icon p a {
    display: block;
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
}



.package-intro-vip {
    margin-bottom: 150px;
}

.package-intro-vip .intro-package {
    position: relative;
}

.package-intro-vip .intro-package .info {
    position: absolute;
    top: 70px;
    right: 0;
    width: 100%;
    max-width: 340px;
    z-index: 9;
}

@media (max-width: 1199px) {
    .package-intro-vip .intro-package .info {
        top: 35px;
        max-width: 300px;
    }
}

@media (max-width: 991px) {
    .package-intro-vip .intro-package .info {
        top: 10px;
        max-width: 250px;
    }
}

@media (max-width: 767px) {
    .package-intro-vip .intro-package .info {
        position: static;
        width: 100%;
        max-width: 100%;
        margin-top: 30px;
    }
}

.package-intro-vip .intro-package .info .gradient-box {
    margin: auto;
    position: relative;
    background-color: #fff;
    box-sizing: border-box;
    background-clip: padding-box;
    border: solid 5px transparent;
    border-radius: 1em;
    padding: 50px 30px 15px 30px;
}

@media (max-width: 1199px) {
    .package-intro-vip .intro-package .info .gradient-box {
        padding: 35px 15px 30px;
    }
}

@media (max-width: 991px) {
    .package-intro-vip .intro-package .info .gradient-box {
        padding: 30px 8px 0px;
    }
}

@media (max-width: 767px) {
    .package-intro-vip .intro-package .info .gradient-box {
        padding: 40px 20px 30px;
    }
}

.package-intro-vip .intro-package .info .gradient-box:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px;
    border-radius: inherit;
    background: linear-gradient(#f3cb5c, #ffee95, #f3cb5c);
}

@media (max-width: 991px) {
    .package-intro-vip .intro-package .info .gradient-box:before {
        margin: -4px;
    }
}

.package-intro-vip .intro-package .info .gradient-box .icon {
    position: absolute;
    top: -42px;
    left: 0;
    right: 0;
    margin: auto;
    width: 80px;
    background: #fff;
    padding: 5px;
    z-index: 9;
}

@media (max-width: 1199px) {
    .package-intro-vip .intro-package .info .gradient-box .icon {
        top: -34px;
        width: 60px;
    }
}

.package-intro-vip .intro-package .info .gradient-box .name {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 27px;
    margin-bottom: 0;
}

@media (max-width: 1199px) {
    .package-intro-vip .intro-package .info .gradient-box .name {
        font-size: 25px;
    }
}

@media (max-width: 991px) {
    .package-intro-vip .intro-package .info .gradient-box .name {
        font-size: 22px;
    }
}

.package-intro-vip .intro-package .info .gradient-box .price {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 40px;
    color: #ba9b1a;
}

@media (max-width: 1199px) {
    .package-intro-vip .intro-package .info .gradient-box .price {
        font-size: 35px;
    }
}

@media (max-width: 991px) {
    .package-intro-vip .intro-package .info .gradient-box .price {
        font-size: 32px;
    }
}

.package-intro-vip .intro-package .info .gradient-box .line-star {
    width: 100%;
    height: 1px;
    background: url(../images/line-gradient.png);
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (max-width: 991px) {
    .package-intro-vip .intro-package .info .gradient-box .line-star {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .package-intro-vip .intro-package .info .gradient-box .line-star {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

.package-intro-vip .intro-package .info .gradient-box .line-star span {
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
    margin: auto;
    display: inline-block;
    width: 70px;
    padding: 0 5px;
    background: #fff;
}

.package-intro-vip .intro-package .info .gradient-box .sapo {
    padding-left: 0;
}

.package-intro-vip .intro-package .info .gradient-box .sapo li {
    list-style: none;
    color: #000;
    font-size: 17px;
    font-weight: bold;
    padding: 0;
}

.package-intro-vip .intro-package .info .gradient-box .sapo li .sapo_icon {
    display: inline-block;
    min-width: 31px;
    height: 31px;
    background: url(../images/gift.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 15px;
}

.package-intro-vip .intro-package .info .gradient-box .sapo li .sapo_icon.nth-1 {
    background: url(../images/crown.svg);
    background-size: 100%;
    background-repeat: no-repeat;
}

.package-intro-vip .intro-package .info .gradient-box .sapo li .sapo_icon.nth-2 {
    background: url(../images/calendar.svg);
    background-size: 100%;
    background-repeat: no-repeat;
}

@media (max-width: 1199px) {
    .package-intro-vip .intro-package .info .gradient-box .sapo li {
        font-size: 15px;
    }
}

@media (max-width: 991px) {
    .package-intro-vip .intro-package .info .gradient-box .sapo li {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .package-intro-vip .intro-package .info .gradient-box .sapo li {
        font-size: 15px;
    }
}

.package-intro-vip .intro-package .info .gradient-box .sapo hr {
    width: 100%;
    height: 1px;
    background: url(../images/line-gradient.png);
    background-repeat: no-repeat;
    background-size: 100%;
    border: none;
}

@media (max-width: 991px) {
    .package-intro-vip .intro-package .info .gradient-box .sapo hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .package-intro-vip .intro-package .info .gradient-box .sapo hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.package-intro-vip .intro-package .info .gradient-box .sapo .upgrade-vip-btn {
    margin-top: 40px;
}

.package-intro-vip .intro-package .info .gradient-box .sapo .upgrade-vip-btn a {
    display: inline-block;
    background: #faf4d2;
    background: -moz-linear-gradient(top, #faf4d2 0%, #fcce01 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #faf4d2), color-stop(100%, #fcce01));
    background: -webkit-linear-gradient(top, #faf4d2 0%, #fcce01 100%);
    background: -o-linear-gradient(top, #faf4d2 0%, #fcce01 100%);
    background: -ms-linear-gradient(top, #faf4d2 0%, #fcce01 100%);
    background: linear-gradient(to bottom, #faf4d2 0%, #fcce01 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#faf4d2', endColorstr='#fcce01', GradientType=0);
    padding: 12px;
    border-radius: 20px;
    box-shadow: 0px 4px 2px 1px #c4a600;
}

@media (max-width: 399px) {
    .package-intro-vip .intro-package .info .gradient-box .sapo .upgrade-vip-btn a {
        max-width: 100%;
    }
}

.package-intro-vip .intro-package .info .gradient-box .sapo .upgrade-vip-btn a img {
    width: 45px;
    height: 45px !important;
    min-width: 45px;
    margin-right: 12px;
}

.package-intro-vip .intro-package .info .gradient-box .sapo .upgrade-vip-btn a span {
    font-weight: bold;
    color: #000;
    font-size: 22px;
    text-transform: uppercase;
    margin-top: 4px;
}

@media (max-width: 991px) {
    .package-intro-vip .intro-package .info .gradient-box .sapo .upgrade-vip-btn a span {
        font-size: 17px;
    }
}

.package-intro-vip .payment-wrap-button {
    position: absolute;
    bottom: 35px;
    left: 14%;
    margin: auto;
    max-width: 410px;
}

.package-intro-vip .payment-wrap-button.mobile {
    position: static;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.package-intro-vip .payment-wrap-button.mobile .btn-payment {
    background: url(../images/viettelpay.jpg);
    background-position: 10px 10px;
    background-repeat: no-repeat;
    background-size: 30px;
    border: 2px solid var(--color);
    padding: 15px;
    padding-left: 49px;
    border-radius: 8px;
}

.package-intro-vip .payment-wrap-button.mobile p {
    font-weight: bold;
    font-size: 19px;
}

.package-intro-vip .payment-wrap-button.mobile p a {
    color: var(--color);
    font-style: italic;
}

@media (max-width: 1199px) {
    .package-intro-vip .payment-wrap-button {
        max-width: 310px;
        bottom: 0;
    }
}

@media (max-width: 991px) {
    .package-intro-vip .payment-wrap-button {
        left: 15%;
        max-width: 210px;
        bottom: 15px;
    }
}

@media (max-width: 767px) {
    .package-intro-vip .payment-wrap-button {
        top: 22%;
        bottom: auto;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 260px;
    }
}

@media (max-width: 575px) {
    .package-intro-vip .payment-wrap-button {
        top: 21%;
        max-width: 160px;
    }
}

@media (max-width: 399px) {
    .package-intro-vip .payment-wrap-button {
        top: 15%;
    }
}

@media (max-width: 330px) {
    .package-intro-vip .payment-wrap-button {
        top: 12%;
        max-width: 165px;
    }
}

.package-intro-vip .payment-wrap-button a {
    display: block;
    cursor: pointer;
}

@media (max-width: 991px) {
    .package-intro-vip .payment-wrap-button .wrap-cssload-thecube {
        margin-top: -17px;
    }
}

@media (max-width: 991px) {
    .package-intro-vip .payment-wrap-button .wrap-cssload-thecube .lds-spinner div::after {
        top: 8px;
    }
}

.page-home #okara_studio {
    width: 80%;
    margin: 0 auto;
}

@media (max-width: 575px) {
    .page-home #okara_studio {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .page-home #okara_studio .owl-wrapper-outer {
        height: auto !important;
    }
}

.page-home #okara_studio img {
    width: 100%;
}

.page-home #okara_studio .owl-dot {
    outline: none;
}

.page-home #okara_studio .owl-dot span {
    background: var(--color);
    opacity: 0.5;
}

.page-home #okara_studio .owl-dot.active span {
    opacity: 1;
}

.view_more {
    margin-top: 30px;
    margin-bottom: 70px;
}

@media (max-width: 767px) {
    .view_more {
        margin-top: 15px;
        margin-bottom: 40px;
    }
}

.view_more a {
    width: 100%;
    max-width: 300px;
    background: #e9e9e9;
    display: block;
    text-align: center;
    margin: auto;
    padding: 10px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

@media (max-width: 767px) {
    .view_more a {
        width: 45px;
        height: 45px;
        border: 2px solid #afafaf;
        border-radius: 50%;
    }
}

.view_more a span {
    text-transform: uppercase;
    color: #737373;
    font-weight: bold;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.view_more a i {
    font-size: 40px;
    color: #b0b0b0;
}

.view_more a:hover {
    background-color: var(--color);
}

@media (max-width: 767px) {
    .view_more a:hover {
        border: none;
    }
}

@media (max-width: 767px) {
    .view_more a:hover i {
        color: #fff;
    }
}

.view_more a:hover span {
    color: #fff;
}

@media (max-width: 767px) {
    .view_more a.view_more_mobile i {
        font-size: 30px;
        margin-top: -3px;
    }
}

#header .accountInfo {
    margin-top: 10px;
}

.singerInfo {
    display: flex;
    perspective-origin: 253.2px 82.2px;
    transform-origin: 253.2px 82.2px;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 4px 4px 4px 4px;
    margin-top: 20px;
}

.singerInfo .singer1 {
    bottom: 0px;
    float: left;
    left: 0px;
    position: relative;
    right: 0px;
    top: 0px;
    padding: 12.6px 0px 16.8px;
    width: 50%;
}

.singerInfo .singerAvatar {
    display: inline-block;
    height: 78.4px !important;
    vertical-align: middle;
    width: 78.4px !important; 
    border-radius: 50% 50% 50% 50%;
    margin: 0px 0px 0px 20px;
    object-fit: cover;
}
.singerInfo .singerText {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    margin-left: 20px;
    width: 45%;
}

.singerInfo .singerName {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0px 0px 11.2px;
    margin-top: 15px;
    overflow: hidden;
}

.singerInfo .singer2 {
    float: left;
    height: 133px;
    padding: 12.6px 0px 16.8px;
    width: 50%;
}

.singerInfo .followButton {
    background-position: 50.4px 50%;
    color:  var(--color) !important;
    display: block;
    text-align: center;
    text-decoration: none solid rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    caret-color: rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-color: var(--color);
    margin: 15.4px 20.4px 0px;
    outline: rgb(255, 255, 255) none 0px;
    max-width: 230px;
    padding:10px;
    text-transform: uppercase;
    font-weight: bold;
}

.singerInfo .singerUid {
    color: #767676;
}


.hover-gift:hover {
    cursor: pointer;
    background-color:#6c757d73!important;
}


.hover-gift {
    background-color: #a4b0bb52;
    border-radius: 10%;
}

.active-gift {
    background-color: #756666c7;
    border-radius: 10%;
}

.btn-red {
    background-color: var(--color) !important;
    color: rgb(255, 255, 255) !important;
    border: 0 !important;
    border-radius: 0 !important;
}

.btn-red.disable{
    background-color: #ec6969 !important;
    color: rgb(255, 255, 255) !important;

}


.top-hit-page a {
    color: #333 !important;
}

.top-hit-page a:hover {
    color: var(--color) !important;
}


.img-buy-coin {
    max-height: 300px !important;
}

.buy-coin {

}

.buy-coin:hover {
    background-color: rgb(146, 144, 144);
    cursor: pointer;
}

.img-user {
    width: 100% !important;
    height: 200px !important;
    object-fit: cover;
    overflow: hidden;
}


.custom-file-label::after {
    content: none !important;
}

.buy-icoin:hover {
    cursor: pointer;
    background-color: #dbd5d5;
}

.gift-profile:hover {
    cursor: pointer;
    background-color: #dbd5d5;
}

.avatar-profile {
    width: 150px  !important;
    height: 150px !important;
    object-fit: cover;
}

.avatar-recording {
    width: 40px  !important;
    height: 40px !important;
    object-fit: cover;
}

.hover-link:hover {
    color: var(--color) !important;
}

.color-black-link {
    color:rgba(0, 0, 0, 0.7)
}

.hover-pointer:hover {
    cursor: pointer;
}

button {
    outline: none !important;
}

img.img-item {
    height: 150px !important;
}

#sing-button {
    border-radius: 31px !important;
    font-size: 20px;
    background: var(--color_gradient)
}

#confirm-button {
    border-radius: 20px !important;
    font-size: 18px;
}

.containerOpenApp {
    position: relative;
    width: 100%;
  }
  
  .containerOpenApp img {
    width: 100%;
    height: auto;
  }
  
  .containerOpenApp .btnOpenApp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    background-color: #e62727;
    color: #fff;
    line-height: 3;
    font-size: 14px;
    font-weight: bold;
  }
  
  .containerOpenApp .btnOpenApp:hover {
    background-color: black;
  }



  #exTab1 .tab-content {
    color : white;
    background-color: #428bca;
    padding : 5px 15px;
  }
  
  #exTab2 h3 {
    color : white;
    background-color: #428bca;
    padding : 5px 15px;
  }
  
  /* remove border radius for the tab */
  
  #exTab1 .nav-pills > li > a {
    border-radius: 0;
  }
  
  /* change border radius for the tab , apply corners on top*/
  
  #exTab3 .nav-pills > li > a {
    border-radius: 4px 4px 0 0 ;
  }
  
  #exTab3 .tab-content {
    color : white;
    background-color: #428bca;
    padding : 5px 15px;
  }

  .iconNotification {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .iconNotification>p{
    margin: 5px 5px;
  }