/* #downloadpage {
    background: rgb(255,93,0);
    background: linear-gradient(90deg, rgba(255,93,0,0.9727241238292192) 0%, rgba(254,88,17,0.9559174011401436) 25%, rgba(254,35,35,0.9139005944174545) 50%, rgba(255,42,61,0.8690826672465861) 75%, rgba(255,63,63,0.7010154403558299) 100%);
}

#downloadpageContent {

}

.download-icon {
    width: 300px;
}

.download-icon:hover {
    cursor: pointer;
}

*/


:local(.downloadPage) {
    /* height: 100vh;
    background: url("../../../assets/images/download_page_rhythm_item.png");
    background-position: top ;
    background-size: 50%;
    background-repeat: no-repeat; */
    height: 100vh;
    background: rgb(255,93,0);
    background: linear-gradient(90deg, rgba(255,93,0,0.9727241238292192) 0%, rgba(254,88,17,0.9559174011401436) 25%, rgba(248, 57, 57, 0.914) 50%, rgba(255,42,61,0.8690826672465861) 75%, rgba(255,63,63,0.7010154403558299) 100%);
}

:local(.phoneItem) {
    /* height: 80vh; */
    z-index: 1;
    position: relative;
}

:local(.phoneItemImg) {
    max-height: 100% !important;
}

:local(.rhythmItem) {
    position: absolute;
    top: 20%;
}
 
:local(.rhythmItemImg) {

}

:local(.buttonDownload:hover) {
    cursor: pointer;
}