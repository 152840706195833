#header {
    box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    background-color: #fff;
}

#header .top-header .header-desktop {
    padding-top: 10px;
    padding-bottom: 10px;
}

#header .top-header .header-desktop .left {
    margin-top: 10px;
}

#header .top-header .header-desktop .left .icon_menu {
    width: 35px;
    min-width: 34px;
    height: 21px;
    margin-right: 15px;
    background: url("../images/icon_menu.svg");
    background-size: cover;
    cursor: pointer;
    margin-top: 4px;
}

#header .top-header .header-desktop .left .icon_menu.active {
    width: 25px;
    height: 25px;
    background: url("../images/menu_close.svg") no-repeat;
    background-size: 25px;
}

#header .top-header .header-desktop .left .logo a {
    display: block;
}

#header .top-header .header-desktop .left .logo a img {
    width: 123px;
}

#header .top-header .header-desktop .menu {
    text-align: right;
    margin-top: 10px;
}

#header .top-header .header-desktop .menu ul {
    padding-left: 0;
    margin-top: 1em;
}

#header .top-header .header-desktop .menu ul li {
    list-style: none;
    display: inline-block;
    margin-left: 30px;
}

#header .top-header .header-desktop .menu ul li a {
    font-size: 16px;
    color: #444444;
    font-weight: bold;
}

#header .top-header .header-desktop .menu ul li a:hover{
    color: var(--color);
}

#header .top-header .header-desktop .right .search a {
    display: inline-block;
    width: 26px;
    height: 26px;
}

#header .top-header .header-desktop .right .user {
    margin-left: 25px;
}

#header .top-header .header-desktop .right .user img {
    width: 36px;
    height: 36px !important;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

#header .top-header .header-mobile {
    position: relative;
    padding: 15px;
}

#header .top-header .header-mobile .icon_menu {
    position: absolute;
    left: 0;
    top: 15px;
    width: 35px;
    height: 22px;
    background: url("../images/icon_menu.svg");
    background-size: cover;
}

#header .top-header .header-mobile .icon_menu.active {
    width: 25px;
    height: 25px;
    background: url("../images/menu_close.svg");
    background-size: cover;
}

#header .top-header .header-mobile .logo {
    text-align: center;
}

#header .top-header .header-mobile .logo a img {
    width: 135px;
    margin-top: -4px;
}

#header .top-header .header-mobile .right {
    position: absolute;
    right: 0;
    top: 15px;
}

#header .top-header .header-mobile .right>a {
    display: block;
    width: 26px;
    height: 26px;
}

#header-logo {
    width: 100px !important;
}

.header-desktop .icon_menu_2 {
    cursor: pointer;
    font-size: 30px;
    margin-right: 10px;
    color: var(--color);
}

.header-mobile .icon_menu_2 {
    cursor: pointer;
    font-size: 30px;
    margin-right: 10px;
    color: var(--color);
    width: 70px;
}

.header-mobile .icon_vip {
    width: 70px;
}

#header .icon_menu_2 .open_nav {
    width: 40px;
}

#header .icon_menu_2 .close_nav {
    width: 40px;
}
