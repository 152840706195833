.recordingInfo { 
    margin: 20px 0 20px;
    font-weight: 700;
    line-height: 2.5em;
}

.recordingInfo .detail-content-title{
    font-size: 18px;
    text-transform: uppercase;
}

.recordingInfo .detail-content {
    clear: both;
}

.recordingInfo .total-listen-icon{
    width: 15px;
    margin-top: -4px;
    margin-right: 5px;
}

.recordingInfo .detail-content-message{
    font-weight: 500;
    font-size: 15px; 
}
.recordingInfo .actionButtonIcon{
    background-position: -5px -1709px;
    box-sizing: border-box;
    color: rgb(128, 128, 128);
    cursor: pointer;
    display: inline-block;
    font-stretch: 75%;
    height: 25px;
    width: 25px;
    text-decoration: none solid rgb(128, 128, 128);
    text-size-adjust: 100%;
    margin: 1px -6px 0px 0px;
    background-position: 0px 5px;
    background-size: 16px;
}

#buttonActionIcon_1 {
    background: rgba(0, 0, 0, 0) url(../images/icons8-micro.png) no-repeat;
    background-position: 0px 5px;
    background-size: 16px;
}


#buttonActionIcon_2 {
    /* background: rgba(0, 0, 0, 0) url(../images/icons8-download.png) no-repeat; */

    background: rgba(0, 0, 0, 0) url(../images/download-icon.svg) no-repeat;
    background-position: 0px 5px;
    background-size: 16px;
}

#buttonActionIcon_3 {
    background: rgba(0, 0, 0, 0) url(../images/share-icon.svg) no-repeat;
    background-position: 0px 5px;
    background-size: 16px;
}

#buttonActionIcon_like {
    background: rgba(0, 0, 0, 0) url(../images/heart-default-icon.svg) no-repeat;
    background-position: 0px 5px;
    background-size: 16px;
}

#buttonActionIcon_liked {
    background: rgba(0, 0, 0, 0) url(../images/heart-icon.svg) no-repeat;
    background-position: 0px 5px;
    background-size: 16px;
}





.recordingInfo .actionButton {
    float: right !important;
    box-sizing: border-box;
    background-color: #e4e4e4;
    display: block;
    float: left;
    font-stretch: 75%;
    height: 30px;
    text-size-adjust: 100%;
    width: 84px;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 5px 5px 5px 5px;
    margin: 15px 0px 0px 10px;
    white-space: nowrap;
    text-align: center;
}



.recordingInfo .actionButtonText {
    bottom: 7px;
    box-sizing: border-box;
    color: #495057;
    cursor: pointer;
    font-stretch: 75%;
    left: 0px;
    position: relative;
    right: 0px;
    top: -7px;
    margin-left: 3px;
}

.recordingInfo .total-listen {
    color: #767676;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 10px;
}


@media (max-width: 575px) {
    .recordingInfo .actionButtonIcon{

        margin: 2px -8px 0px 0px;

    }

    .recordingInfo .actionButtonText{
        display:none;
    }

    .recordingInfo .actionButton {
        width: 30px;
    }
}